import { Getter } from "vuex";
import { IState } from './state';

const title: Getter<IState, IState> = (state) => state.title;
const link: Getter<IState, IState> = (state) => state.link;
const text: Getter<IState, IState> = (state) => state.text;
const imageUri: Getter<IState, IState> = (state) => state.imageUri;
const backgroundColor: Getter<IState, IState> = (state) => state.backgroundColor;
const floatDirectionHorizontal: Getter<IState, IState> = (state) => state.floatDirectionHorizontal;
const floatDirectionVertical: Getter<IState, IState> = (state) => state.floatDirectionVertical;


export default {
	title,
	link,
	text,
	imageUri,
	backgroundColor,
	floatDirectionHorizontal,
	floatDirectionVertical
}


import Vue from 'vue'

interface PuffData {}

interface PuffMethods {}

interface PuffComputed {
    title: string
    link: string
    text: string
    imageUri: string
    backgroundColor: string
    floatDirection: string
}

interface PuffProps {}

export default Vue.extend<PuffData, PuffMethods, PuffComputed, PuffProps>({
	name: 'Puff',
	computed: {
        title() {
            return this.$store.getters.title
        },
        link() {
            return this.$store.getters.link
        },
        text() {
            return this.$store.getters.text
        },
        imageUri() {
            return this.$store.getters.imageUri
        },
        backgroundColor() {
            return this.$store.getters.backgroundColor
        },
        floatDirection() {
            if(this.$store.getters.floatDirectionVertical === 'max') {
                return this.$store.getters.floatDirectionHorizontal
            } else {
                return this.$store.getters.floatDirectionVertical
            }
        }
    }
})

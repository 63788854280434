export interface IState {
	title: string
	link: string
	text: string
	imageUri: string
	backgroundColor: string
	floatDirectionHorizontal: string
	floatDirectionVertical: string
}

const deafultState: IState = {
	title: '',
	link: '',
	text: '',
	imageUri: '',
	backgroundColor: '',
	floatDirectionHorizontal: '',
	floatDirectionVertical: ''
}

export default deafultState;
